import axios from 'axios';
import React from 'react';
import { Card, NotificationType } from '../globalTypes';
import { useNotificationContext } from './NotificationContext';

type CardContextProps = {
  cards: Card[];
  addCard: () => void;
  updateCard: (id: number, card: Partial<Card>, cd?: () => void) => void;
  deleteCard: (id: number, cd?: () => void) => void;
  setPrimaryCard: (id: number, cd?: () => void) => void;
  isLoading: boolean;
};

export const CardContext = React.createContext<CardContextProps>({
  cards: [],
  addCard: () => {},
  updateCard: () => {},
  deleteCard: () => {},
  setPrimaryCard: () => {},
  isLoading: true
});

export const useCardContext = () => React.useContext(CardContext);

const AXIOS_HEADERS = {
  Accept: 'application/json',
  'Content-Type': 'application/json',
  'X-CSRF-Token': document.querySelector('meta[name="csrf-token"]')?.getAttribute('content')
};

enum PostMessageTypes {
  SUCCESS = 'NewCardSuccess',
  FAILED = 'NewCardFailed'
}

export const CardProvider: React.FC = ({ children }) => {
  const [cards, setCards] = React.useState<Card[]>([]);
  const [isLoading, setIsLoading] = React.useState(true);
  const { addNotification } = useNotificationContext();

  const handlePostMessage = (event: MessageEvent<PostMessageTypes>) => {
    switch (event.data) {
      case PostMessageTypes.SUCCESS:
        getCards();
        addNotification({
          id: 'cardsAdded',
          message: { key: 'notification.card_added', values: [] },
          type: NotificationType.SUCCESS
        });
        break;
      case PostMessageTypes.FAILED:
        setIsLoading(false);
        addNotification({
          id: 'cardsError',
          message: { key: 'notification.error.card_add' },
          type: NotificationType.ERROR
        });
        break;
    }
  };

  React.useEffect(() => {
    getCards();

    window.addEventListener('message', handlePostMessage);

    return () => {
      window.removeEventListener('message', handlePostMessage);

    };
  }, []);

  const getCards = () => {
    axios
      .get<Card[]>('/api/v2/user_cards', { headers: AXIOS_HEADERS })
      .then(response => {
        setCards(response.data);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        addNotification({
          id: 'cardsError',
          message: { key: 'notification.error.card_get' },
          type: NotificationType.ERROR
        });
      });
  };

  const addCard = () => {
    setIsLoading(true);
    const newWindow = window.open('', 'new_card', 'width=505,height=500');
    axios
      .post('/api/v2/user_cards', {}, { headers: AXIOS_HEADERS })
      .then(response => {
        newWindow?.location.replace(response.data.url);
        setIsLoading(false);
      })
      .catch(() => {
        setIsLoading(false);
        addNotification({
          id: 'cardsError',
          message: { key: 'notification.error.card_add' },
          type: NotificationType.ERROR
        });
      });
  };

  const updateCard = (id: number, card: Partial<Card>, cb?: () => void) => {
    setIsLoading(true);
    axios
      .patch(`/api/v2/user_cards/${id}`, { user_card: card }, { headers: AXIOS_HEADERS })
      .then(response => {
        setCards(cards.map(c => (c.id === id ? { ...c, ...card } : c)));
        if (cb) cb();
        setIsLoading(false);
        const last4 = response.data.find((c: Partial<Card>) => c.id === id)?.pan?.slice(-4);
        addNotification({
          id: 'cardsUpdated',
          message: { key: 'notification.card_renamed', values: [last4] },
          type: NotificationType.SUCCESS
        });
      })
      .catch(() => {
        setIsLoading(false);
        addNotification({
          id: 'cardsError',
          message: { key: 'notification.error.card_rename' },
          type: NotificationType.ERROR
        });
      });
  };

  const deleteCard = (id: number, cd?: () => void) => {
    setIsLoading(true);
    axios
      .delete(`/api/v2/user_cards/${id}`, { headers: AXIOS_HEADERS })
      .then(response => {
        setCards(response.data);
        if (cd) cd();
        setIsLoading(false);
        const last4 = cards.find(c => c.id === id)?.pan?.slice(-4) as string;
        addNotification({
          id: 'cardsDeleted',
          message: { key: 'notification.card_deleted', values: [last4] },
          type: NotificationType.SUCCESS
        });
      })
      .catch(() => {
        setIsLoading(false);
        addNotification({
          id: 'cardsError',
          message: { key: 'notification.error.card_delete' },
          type: NotificationType.ERROR
        });
      });
  };

  const setPrimaryCard = (id: number, cd?: () => void) => {
    setIsLoading(true);
    axios
      .patch(`/api/v2/user_cards/${id}/primary`, {}, { headers: AXIOS_HEADERS })
      .then(response => {
        const list: Card[] = cards.map(c => ({ ...c, primary: c.id === id }));
        setCards(list.sort((a, b) => (a.primary ? -1 : b.primary ? 1 : 0)));
        setIsLoading(false);
        if (cd) cd();
        const last4 = response.data[0].pan?.slice(-4);
        addNotification({
          id: 'cardsPrimarySet',
          message: { key: 'notification.card_default', values: [last4] },
          type: NotificationType.SUCCESS
        });
      })
      .catch(() => {
        setIsLoading(false);
        addNotification({
          id: 'cardsError',
          message: { key: 'notification.error.card_default' },
          type: NotificationType.ERROR
        });
      });
  };

  return (
    <CardContext.Provider
      value={{ cards, addCard, updateCard, deleteCard, isLoading, setPrimaryCard }}
    >
      {children}
    </CardContext.Provider>
  );
};
