import React, { useContext, useEffect } from 'react';
import { TabsNames } from '../../context/globalState';
import { Tab } from './Tab';
import { PersonalCabinetContext } from '../../context';

export const Tabs = () => {
  const { setCurrentTab } = useContext(PersonalCabinetContext);
  const { adsPayments, transactions, paidAdsTransactions } = TabsNames;

  useEffect(() => {
    const currentPathName = document.location.pathname;
    if (currentPathName === transactions.path) {
      setCurrentTab(transactions);
    } else if (currentPathName === paidAdsTransactions.path) {
      setCurrentTab(paidAdsTransactions);
    } else setCurrentTab(adsPayments);
  }, []);

  return (
    <div className="profile-items_filters profile-items_filters--operations">
      {[adsPayments, transactions, paidAdsTransactions].map(i => (
        <Tab key={i.name} tab={i} />
      ))}
    </div>
  );
};
