import styled from 'styled-components';
import { Container } from '../../Shared/styled';

export const CardListContainer = styled(Container)`
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  padding: 20px 15px;
  box-sizing: border-box;
  background-color: #fff;
`;

export const CardBlock = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100%;
  padding: 15px;
  border-radius: 14px;
  border: 1px solid #eaebf2;
  background-color: #f6f7fa;
  box-sizing: border-box;
  gap: 10px;
  -webkit-tap-highlight-color: transparent;
`;

export const CardInfo = styled.div`
  flex: 1;
`;

export const CardNumber = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: #212c3a;
  text-overflow: ellipsis;
  white-space: nowrap;
  overflow: hidden;

  @media (max-width: 416px) {
    max-width: 227px;
  }
`;

export const CardSecondaryText = styled.div`
  font-size: 15px;
  line-height: 18px;
  color: #8d94ad;
`;

export const CardType = styled.img`
  width: 34px;
  height: 21px;
`;

export const CardDetails = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  gap: 5px;
`;

export const CardWarning = styled(CardSecondaryText)`
  color: #ff586d;
`;

export const CardMain = styled(CardSecondaryText)`
  color: #3db460;
`;

export const CardActions = styled.div`
  width: 50px;
  height: 50px;
`;
