import React, { useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';
import { GlobalStyle } from 'packs/autocatalog/styles';
import { useLangUpdate, useQueryState } from 'packs/autocatalog/hooks';
import { Loader } from 'packs/autocatalog/components/desktop';
import { RoutePath, apiPaths } from 'packs/autocatalog/constants';
import { DropdownOption, MobileHeader } from 'components';
import { Carousel, SpecSummary, DropdownSelect } from 'packs/autocatalog/components/mobile';
import { CarDimensions } from 'packs/autocatalog/components/desktop';
import { useAutocatalogContext } from 'packs/autocatalog/context';
import {
  Container,
  Section,
  Title,
  DimensionsContainer,
  SpecTitle,
  SpecTable,
  SpecName,
  SpecValue,
  LoaderContainer
} from './styled';
import { useCatalogGenerations, useCatalogItem, useModifications } from 'packs/autocatalog/api';

export const ModelMobile = () => {
  useLangUpdate();
  const { t } = useTranslation();
  const history = useHistory();
  const [query] = useQueryState();
  const ctx = useAutocatalogContext();
  const params: { id: string; generation: string } = useParams();
  const id = params?.id;
  const generationId = params?.generation;
  const makeId = query.make ?? '';
  const modelId = (query.model || query.model_group) ?? '';
  const generationUrl = `?make=${makeId}&${query.model_group ? 'model_group' : 'model'}=${modelId}`;

  const [modification, setModification] = useState<DropdownOption | null>(null);

  const { data, isLoading } = useCatalogItem(apiPaths.items(`/${id}?p=mobile`));
  const modifications = useModifications(apiPaths.items(`/${id}/modifications`));
  const generations = useCatalogGenerations(apiPaths.generations(generationUrl));
  const generationIcon = generations.data
    .find(item => item.id === +generationId)
    ?.categories[0]?.icon;

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [history.location.pathname]);

  useEffect(() => {
    window?.TapBarOnScroll?.rebindScrollHandler?.();
  }, [window?.TapBarOnScroll]);

  const slides = data?.photos.map(url => ({ url, label: '' })) ?? [];
  const hasSlides = slides.length > 0;
  const geometry = data?.geometry;
  const initialGeneration = ctx.generations.find(({ id }) => id === +generationId) ?? null;
  const modificationOptions: DropdownOption[] =
    modifications.data?.flatMap(item => [
      { label: item.name, value: item.name, isGroup: true },
      ...item.items.map(nested => {
        const label = [nested.engine_capacity, nested.power, nested.transmission, nested.gear]
          .filter(Boolean)
          .join(' / ');

        return {
          label,
          value: nested.id
        };
      })
    ]) ?? [];

  const title = `${data?.make.name} ${data?.model.name || data?.model_group.name} ${data?.generation
    .name}`;

  const iconsSection = data?.sections.find(section => section.type === 'icons');
  const sections = data?.sections.filter(section => !section.type);

  useEffect(() => {
    if (modification || !modificationOptions.length) return;

    const initialModification = modificationOptions.find(({ value }) => value === +params.id);

    setModification(initialModification || null);
  }, [modification, modificationOptions]);

  const onBackBtnClick = () => {
    history.push(RoutePath.autocatalog + history.location.search);
  };

  const onModificationSelect = (item: DropdownOption) => {
    history.replace(`${RoutePath.model}/${generationId}/${item.value}` + history.location.search);
    setModification(item);
  };

  return (
    <Container>
      <GlobalStyle />
      <MobileHeader
        title={initialGeneration?.name ?? data?.generation.name ?? ''}
        onBackBtnClick={onBackBtnClick}
      />

      {isLoading && !data ? (
        <LoaderContainer>
          <Loader />
        </LoaderContainer>
      ) : (
        <>
          {hasSlides && <Carousel title={title} slides={slides} />}
          <Section>
            <Title>
              {title}, {data?.category.name}
            </Title>

            <DropdownSelect
              isOutlined
              isClearable={false}
              value={modification}
              options={modificationOptions}
              isShort={modificationOptions.length < 7}
              label={t('specsFilter.modification')}
              placeholder={t('specsFilter.modification')}
              onChange={onModificationSelect}
            />
          </Section>

          <Section>
            <DimensionsContainer>
              <CarDimensions
                width={geometry?.width ?? 0}
                height={geometry?.height ?? 0}
                length={geometry?.length ?? 0}
                icon={generationIcon}
              />
            </DimensionsContainer>
          </Section>

          {iconsSection ? (
            <Section isTransparent>
              <SpecSummary fields={iconsSection.fields} />
            </Section>
          ) : null}

          {sections?.map(section => {
            return (
              <Section key={section.name}>
                {section.name && <SpecTitle>{section.name}</SpecTitle>}
                <SpecTable>
                  {section.fields.map(field => {
                    return (
                      <React.Fragment key={field.label}>
                        <SpecName>{field.label}</SpecName>
                        <SpecValue>{isEmpty(field.value) ? '-' : field.value}</SpecValue>
                      </React.Fragment>
                    );
                  })}
                </SpecTable>
              </Section>
            );
          })}
        </>
      )}
    </Container>
  );
};
