import { func, object } from 'prop-types';

export const TabsNames = {
  adsPayments: {
    name: 'adsPayments',
    path: '/profile'
  },
  transactions: {
    name: 'transactions',
    path: '/profile/transactions'
  },
  paidAdsTransactions: {
    name: 'paidAdsTransactions',
    path: '/profile/paid_ads_transactions'
  }
};

export const globalState = {
  currentTab: TabsNames.adsPayments,
  setCurrentTab: null,
  selectedRow: null,
  setSelectedRow: null
};

globalState.propTypes = {
  currentTab: object,
  setCurrentTab: func,
  selectedRow: object,
  setSelectedRow: func
};
