import React, { FC } from 'react';
import { useCardContext } from '../../../../context/CardContext';
import { useDesktopControlsContext } from '../../../../context/DesktopControlsContext';
import { Card, MenuItemType, Position } from '../../../../globalTypes';
import deleteIcon from '../../../../images/delete.svg';
import renameIcon from '../../../../images/edit.svg';
import starIcon from '../../../../images/star.svg';
import MenuItem from './MenuItem';
import { GtmDataStatUserCards } from '../../../../enums/gtmDataStatUserCards';
import { ContextMenuContainer } from './styled';

interface ContextMenuProps {
  isClose: boolean;
  position: Position;
  card: Card | null;
}

const ContextMenu: FC<ContextMenuProps> = ({ isClose, position, card }) => {
  const { openDeleteDialog, card: selectedCard, openRenameDialog } = useDesktopControlsContext();
  const { setPrimaryCard } = useCardContext();

  const menus: MenuItemType[] = [
    {
      id: 1,
      text: 'make_default',
      action: () => setPrimaryCard(selectedCard?.id as number),
      icon: starIcon,
      dataStat: GtmDataStatUserCards.userCardPrimary
    },
    {
      id: 2,
      text: 'rename_card',
      action: openRenameDialog,
      icon: renameIcon,
      dataStat: GtmDataStatUserCards.userCardRenaming
    },
    {
      id: 3,
      text: 'delete_card',
      action: openDeleteDialog,
      icon: deleteIcon,
      dataStat: GtmDataStatUserCards.userCardRemoval
    }
  ];

  return (
    <ContextMenuContainer position={position} isClose={isClose}>
      {menus.slice(card?.primary ? 1 : 0).map(menu => (
        <MenuItem key={menu.id} menu={menu} />
      ))}
    </ContextMenuContainer>
  );
};

export default ContextMenu;
