import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { ContextWrapper } from './context/ContextWrapper';
import { Tabs } from './components/tabs';
import { Content } from './components/content';
import az from './locales/az.json';
import ru from './locales/ru.json';

const PersonalCabinetApp = ({ dataset }) => (
  <ContextWrapper {...{ dataset }}>
    <BrowserRouter>
      <Tabs />
      <Content />
    </BrowserRouter>
  </ContextWrapper>
);

export default { App: PersonalCabinetApp, az, ru };
