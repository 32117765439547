import React from 'react';
import { AddNewCardContainer } from './styled';
import noCardImage from '../../../images/no-cards.svg';
import { useTranslation } from 'react-i18next';
import { useCardContext } from '../../../context/CardContext';
import { GtmDataStatUserCards } from '../../../enums/gtmDataStatUserCards';

const AddNewCard = () => {
  const { addCard } = useCardContext();
  const { t } = useTranslation();
  return (
    <AddNewCardContainer onClick={addCard} data-stat={GtmDataStatUserCards.userCardAddition}>
      <img src={noCardImage} alt="Add new card" />
      <p>{t('add_new_card')}</p>
    </AddNewCardContainer>
  );
};

export default AddNewCard;
