import React, { useContext, useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import * as classNames from 'classnames';
import { useAxios } from '../../../hooks/useAxios';
import { getInvoiceByIdPath } from '../routes';
import { InvoicesAppContext } from '../context';
import { GtmDataStatInvoices } from '../constants';

export const PaymentButton = ({
  buttonClassName,
  invoiceId,
  checkUnpaidStatus,
  disabled,
  initializePayment,
  selectedPayment,
  isUserCardSelected,
  localePath = 'modal.payBtn'
}) => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const payButtonClassNames = classNames(
    buttonClassName,
    { [`${buttonClassName}--loading`]: loading }
  );

  const {
    setWindowOpened,
    winOpened
  } = useContext(InvoicesAppContext);

  const invoicesDataSuccess = (res, isInitialCall, openedWin) => {
    if (!['wallet', 'terminal'].includes(selectedPayment)) {
      initializePayment(selectedPayment).then(value => {
        checkUnpaidStatus(res, value, selectedPayment, openedWin);
      });
    } else {
      checkUnpaidStatus(res, '', selectedPayment, '');
    }
    if (res.status === 'unpaid') setLoading(false);
  };

  const { callRequest: getActualInvoicesData } = useAxios({
    method: 'get',
    url: getInvoiceByIdPath(invoiceId),
    onSuccess: (data, isInitialCall, openedWin) => {
      invoicesDataSuccess(data, isInitialCall, openedWin);
    },
    onError: (error, openedWin) => {
      console.log('Request error:', error);
      openedWin?.close();
    }
  });

  const onClick = useCallback(() => {
    let openedWin;
    if (winOpened) winOpened.close();
    if (!['wallet', 'terminal'].includes(selectedPayment) && !window.ReactNativeWebView) {
      openedWin = window.open('', 'new_payment', 'width=580,height=750');
      setWindowOpened(openedWin);
    }
    getActualInvoicesData({ openedWin });
    setLoading(true);
  }, [getActualInvoicesData, setLoading]);

  return (
    <button
      className={payButtonClassNames}
      value={t('pay')}
      type="button"
      onClick={onClick}
      data-stat={isUserCardSelected ? GtmDataStatInvoices.invoice : null}
      disabled={disabled || loading}
    >
      {loading ? <span className="loading" /> : t(localePath)}
    </button>
  );
};
