import React, { FC } from 'react';
import { Trans, useTranslation } from 'react-i18next';
import { useCardContext } from '../../context/CardContext';
import { GtmDataStatUserCards } from '../../enums/gtmDataStatUserCards';
import { CenterSecondaryText } from '../Shared/styled';
import Button from './Button';

interface AddNewCardProps {
  isDesktop?: boolean;
}

const DESKTOP_MARGIN_BOTTOM = 120;
const MOBILE_MARGIN_BOTTOM = 0;

const AddNewCard: FC<AddNewCardProps> = ({ isDesktop }) => {
  const { t } = useTranslation();
  const { addCard } = useCardContext();

  return (
    <>
      <Button
        onClick={addCard}
        type="button"
        fixedWidth={isDesktop}
        data-stat={GtmDataStatUserCards.userCardAddition}
        mb={isDesktop ? DESKTOP_MARGIN_BOTTOM : MOBILE_MARGIN_BOTTOM}
      >
        {t('add_new_card')}
      </Button>
      <CenterSecondaryText>
        <Trans
          i18nKey="user_card_description"
          values={[t('user_agreement'), t('offer_agreement')]}
          components={[
            <a
              href="/pages/terms-and-conditions"
              key="user_agreement"
              target="_blank"
              rel="noopener noreferrer"
            />,
            <a
              href="/pages/proposal"
              key="offer-agreement"
              target="_blank"
              rel="noopener noreferrer"
            />
          ]}
        />
      </CenterSecondaryText>
    </>
  );
};

export default AddNewCard;
